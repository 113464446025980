import styled from 'styled-components'

import { ExtraHeaderText } from 'src/components/text/extra-header-text'
import { BlueButton } from 'src/components/button/blue-button'
import { NextIcon } from 'src/components/icon/next-icon'
import { ContentContainer } from 'src/components/container/content-container'
import { LandingBackground } from './landing-bg'

export const Landing = () => {
  return (
    <LandContainer>
      <LandingBackground />
      <ContentContainer>
        <LandingContent>
          <ExtraHeaderText>The fastest leveraged trading platform in DeFi.</ExtraHeaderText>
          <LandingDescriptionText>
            Trade crypto, forex and commodities with up to 500x leverage directly from your wallet on Tigris.
          </LandingDescriptionText>
          <BlueButton>
            Open App
            <NextIcon/>
          </BlueButton>
        </LandingContent>
      </ContentContainer>
    </LandContainer>
  )
}

const LandContainer = styled.div`
  position: relative;
  overflow: hidden;

  height: 680px;
`

const LandingContent = styled.div`
  padding-top: 120px;

  max-width: 600px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;

  gap: 40px;

  overflow: hidden;

  z-index: 2;

  @media screen and (max-width: 768px) {
    gap: 34px;
    max-width: 500px;
    padding-top: 90px;
  }

  @media screen and (max-width: 425px) {
    gap: 20px;
    max-width: 280px;
    padding-top: 50px;
  }
`
const LandingDescriptionText = styled.p`
  padding: 0;
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 28px;

  color: ${(props) => props.theme.whiteAlt};

  @media screen and (max-width: 1024px) {
    max-width: 400px;
  }

  @media screen and (max-width: 820px) {
    max-width: 300px;
  }

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }
`
