import { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Container } from '../components/container/container'
import { GradientButton } from '../components/button/gradient-button'
import { MobileNav } from '../components/page-elements/header/mobile-nav'
import { ReduceIcon } from '../components/icon/reduce-icon'
import { HeaderLink } from '../components/link/header-link'

import { LOGO } from '../config/images'

export const Header = () => {
  const [isOpened, setIsOpend] = useState(false)

  return (
    <HeaderWrapper>
      <Container>
        <ContentWrapper>
          <LogoGroup>
            <Logo className="large font-chango">
              <img src={LOGO} alt="logo" height="32px" />
            </Logo>
            <ReduceIcon onClick={() => setIsOpend(true)} />
          </LogoGroup>
          <LinkBox>
            <LinkGroup>
              <HeaderLink url={'https://docs.tigris.trade'}>Documentation</HeaderLink>
              <HeaderLink url={'https://tigristrade.medium.com/'}>Medium</HeaderLink>
              <HeaderLink url={'https://discord.gg/9YqkK29Qx2'}>Discord</HeaderLink>
              <GradientButton
                url={
                  'https://app.camelot.exchange/?token1=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&token2=0x3a33473d7990a605a88ac72a78ad4efc40a54adb'
                }
                target="_blank"
                filled={true}
              >
                Buy TIG
              </GradientButton>
            </LinkGroup>
            <GradientButton url={'https://app.tigris.trade/trade/BTC-USD'}>Launch App</GradientButton>
          </LinkBox>
          <MobileNav opened={isOpened} onClose={() => setIsOpend(false)} />
        </ContentWrapper>
      </Container>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 64px;

  border-bottom: 1px solid ${(props) => props.theme.darkGrey};

  background-color: ${(props) => props.theme.black};

  z-index: 30;
`

const ContentWrapper = styled.div`
  padding: 16px 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  @media (max-width: 768px) {
    padding: 16px 20px;
  }
`

const Logo = styled.div`
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  color: ${(props) => props.theme.white};

  @media (max-width: 580px) {
    img {
      width: 100px;
    }
  }
`

const LogoGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;

  gap: 20px;
`

const LinkGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  gap: 20px;

  @media (max-width: 580px) {
    display: none;
  }
`

const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 14px;
`
