import styled from 'styled-components'

interface HeaderLinkProps {
  children: React.ReactNode
  url: string
  target?: string
}

export const HeaderLink = ({ children, url, target = '_blank' }: HeaderLinkProps) => {
  return (
    <HeaderLinkWrapper href={url} target={target}>
      {children}
    </HeaderLinkWrapper>
  )
}

const HeaderLinkWrapper = styled.a`
  text-decoration: none;

  font-size: 16px;
  font-weight: 500;
  line-height: 21px;

  color: ${(props) => props.theme.white};

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  cursor: pointer;

  gap: 10px;

  .badge {
    position: absolute;
    top: -2px;
    right: -10px;
  }

  @media (max-width: 580px) {
    font-size: 12px;
  }
`
