import styled from 'styled-components'

import { CloseIcon } from '../../icon/close-icon'
import { DocumentIcon } from '../../icon/document-icon'
import { GradientButton } from '../../button/gradient-button'
import { HeaderLink } from '../../link/header-link'

interface MobileNavProps {
  opened: boolean
  onClose: () => void
}

export const MobileNav = ({ opened, onClose }: MobileNavProps) => {
  return (
    <MobileNavContainer opened={opened}>
      <MobileNavBox>
        <MobileNavHeader>
          <CloseIcon onClick={onClose} />
          <LinkBox>
            <GradientButton
              url={
                'https://app.camelot.exchange/?token1=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&token2=0x3a33473d7990a605a88ac72a78ad4efc40a54adb'
              }
              target="_blank"
              filled={true}
            >
              Buy TIG
            </GradientButton>
            <GradientButton url={'https://app.tigris.trade'}>Launch App</GradientButton>
          </LinkBox>
        </MobileNavHeader>
        <MobileNavContent>
          <HeaderLink url="https://docs.tigris.trade">
            <DocumentIcon />
            Documentation
          </HeaderLink>
          <HeaderLink url="https://discord.gg/tigris">Discord</HeaderLink>
        </MobileNavContent>
      </MobileNavBox>
    </MobileNavContainer>
  )
}

interface MobileNavContainerProps {
  opened: boolean
}

const MobileNavContainer = styled.div<MobileNavContainerProps>`
  display: ${(props) => (props.opened ? 'block' : 'none')};
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  backdrop-filter: brightness(40%);
`

const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

const MobileNavBox = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 325px;
  height: 160px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  gap: 16px;

  background-color: ${(props) => props.theme.black};
`

const MobileNavHeader = styled.div`
  padding: 12px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.greyAlt};
`

const MobileNavContent = styled.div`
  padding: 0px 20px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  gap: 20px;
`
